import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import image1 from 'src/Assests/hero-section/1.png'
import image3 from 'src/Assests/hero-section/10.png'
import Limage3 from 'src/Assests/hero-section/11.png'
import Limage6 from 'src/Assests/hero-section/12.png'
import Limage1 from 'src/Assests/hero-section/2.png'
import Limage2 from 'src/Assests/hero-section/3.png'
import Limage4 from 'src/Assests/hero-section/4.png'
import Limage5 from 'src/Assests/hero-section/5.png'
import image2 from 'src/Assests/hero-section/6.png'
import image4 from 'src/Assests/hero-section/7.png'
import image5 from 'src/Assests/hero-section/8.png'
import image6 from 'src/Assests/hero-section/9.png'
import Caretdown from 'src/assets/images/caret-down.svg'
import useVisible from 'src/hooks/useVisible'
import users from '../../Assests/images/Users.png'
import LaptopSliderComponent from "../base/laptopSlider"
import SliderComponent from "../base/slider"

const slidesDataM = [
    { id: 1, image: `${image6}?v=${new Date().getTime()}`, text: '1' },
    { id: 2, image: `${image4}?v=${new Date().getTime()}`, text: '2' },
    { id: 3, image: `${image1}?v=${new Date().getTime()}`, text: '2' },
    { id: 4, image: `${image5}?v=${new Date().getTime()}`, text: '2' },
    { id: 5, image: `${image2}?v=${new Date().getTime()}`, text: '2' },
    { id: 6, image: `${image3}?v=${new Date().getTime()}`, text: '2' },
];



const slidesData = [
    { id: 1, image: `${Limage6}`, text: '1' },
    { id: 2, image: `${Limage4}`, text: '2' },
    { id: 3, image: `${Limage1}`, text: '3' },
    { id: 4, image: `${Limage5}`, text: '4' },
    { id: 5, image: `${Limage2}`, text: '5' },
    { id: 6, image: `${Limage3}`, text: '6' },
];




const HeroSection = ({ laptop, mob }) => {
    const { ref, controls } = useVisible()
    const [screen, setScreen] = useState("web")
    const [stopScreen, setStopScreen] = useState(false)
    const [activeAnimation, setActiveAnimation] = useState(false)
    const [activeImageIndex, setActiveImageIndex] = useState(1)
    const [imgId, setImgId] = useState('')

    useEffect(() => {
        if (!stopScreen) {
            const intervalId = setInterval(() => {
                setScreen((currentScreen) => currentScreen === "web" ? "app" : "web");
                setActiveImageIndex(1)
                setActiveAnimation(true);
                setImgId('');
                setTimeout(() => {
                    setActiveAnimation(false); // Reset activeAnimation after animation duration
                }, 1100);
            }, 5000);

            return () => clearInterval(intervalId);
        } else {
            return;
        }
    }, [stopScreen, screen]); // Add 'screen' as a dependency



    const handleImgId = (id) => {
        if (imgId == id) {
            setImgId('')
        } else {
            setActiveImageIndex(id)
        }
    }

    const scrollToDiv = (divId) => {
        var element = document.getElementById(divId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };


    return (
        <div className="hero-section container" id='hero-section'>

            <motion.div
                variants={{
                    hidden: { opacity: 0, y: -75 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial={'hidden'}
                animate={controls}
                transition={{ duration: 0.5, delay: 0.5 }}
            >
                <div className="hero-title fs-45 text-center fw-medium f-family-poppin">
                    <p>Leading <span className="text-color">{screen == "web" ? "WEB" : "APP"}</span> Developers</p>
                </div>            </motion.div>

            <div className="content-container" onMouseEnter={() => { setStopScreen(true) }} onMouseLeave={() => { setStopScreen(false) }}>
                <AnimatePresence>

                    <motion.div
                        key="web"
                        initial={{ opacity: 0, x: -100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 100 }}
                        transition={{ duration: 0.5 }}
                    // style={{ position: 'absolute', width: '100%' }}
                    >

                        <div ref={ref} className='project-hero'>
                            <div className='main-cards'>
                                <div className='left-card'>
                                    <div className={`card-left pl-50`} onClick={() => handleImgId(1)}>
                                        <div className={`card-left-icon ${imgId == 1 || activeImageIndex == 1 ? 'active' : ''}`}>
                                            <img src={users} alt="users" loading='lazy' />
                                        </div>
                                        <div className='card-left-title'>
                                            <div className='fw-semi-bold fs-20 f-family-poppin mb-5'>
                                                Team of  App Experts
                                            </div>
                                            <div className='fs-12'>
                                                You will be assigned our top  developers to build your software
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`card-left `} onClick={() => handleImgId(2)}>
                                        <div className={`card-left-icon ${imgId == 2 || activeImageIndex == 2 ? 'active' : ''}`}>
                                            <img src={users} alt="users" loading='lazy' />
                                        </div>
                                        <div className='card-left-title' >
                                            <div className='fw-semi-bold fs-20 f-family-poppin mb-5'>
                                                1-ON-1, In Person
                                            </div>
                                            <div className='fs-12'>
                                                In-person meetings with project managers, guiding every step together
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`card-left  pl-50 `} onClick={() => handleImgId(3)}>
                                        <div className={`card-left-icon ${imgId == 3 || activeImageIndex == 3 ? 'active' : ''}`}>
                                            <img src={users} alt="users" loading='lazy' />
                                        </div>
                                        <div className='card-left-title'>
                                            <div className='fw-semi-bold fs-20 f-family-poppin mb-5'>
                                                Best Code Practices
                                            </div>
                                            <div className='fs-12'>
                                                You will be assigned our top  developers to build your software
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    screen === 'web' ? <div className={`center-card ${activeAnimation ? 'left' : ''}`}>
                                        <LaptopSliderComponent slide={stopScreen} imgId={imgId} currentImg={setActiveImageIndex} slidesData={slidesData} initial={laptop} />

                                    </div> : <div className={`center-card ${activeAnimation ? 'left' : ''}`}>

                                        <SliderComponent slide={stopScreen} imgId={imgId} currentImg={setActiveImageIndex} slidesData={slidesDataM} initial={mob} />

                                    </div>
                                }
                                <div className='right-card'>
                                    <div className={`card-right  pr-50 `} onClick={() => handleImgId(4)}>
                                        <div className={`card-right-title `}>
                                            <div className='fw-semi-bold fs-20 f-family-poppin mb-5'>
                                                Privacy & Cybersecurity
                                            </div>
                                            <div className='fs-12 text-right'>
                                                You will be assigned our top  developers to build your software
                                            </div>
                                        </div>
                                        <div className={`card-right-icon  ${imgId == 4 || activeImageIndex == 4 ? 'active' : ''}`}>
                                            <img src={users} alt="users" loading='lazy' />
                                        </div>
                                    </div>
                                    <div className='card-right' onClick={() => handleImgId(5)}>
                                        <div className={`card-right-title `}>
                                            <div className='fw-semi-bold fs-20 f-family-poppin mb-5'>
                                                Cross Platform Development
                                            </div>
                                            <div className='fs-12 text-right'>
                                                You will be assigned our top  developers to build your software
                                            </div>
                                        </div>
                                        <div className={`card-right-icon ${imgId == 5 || activeImageIndex == 5 ? 'active' : ''}`}>
                                            <img src={users} alt="users" loading='lazy' />
                                        </div>
                                    </div>
                                    <div className={`card-right pr-50 `} onClick={() => handleImgId(6)}>

                                        <div className={`card-right-title`}>
                                            <div className='fw-semi-bold fs-20 f-family-poppin mb-5'>
                                                Support & Maintenance
                                            </div>
                                            <div className='fs-12 text-right'>
                                                You will be assigned our top  developers to build your software
                                            </div>
                                        </div>
                                        <div className={`card-right-icon ${imgId == 6 || activeImageIndex == 6 ? 'active' : ''}`}>
                                            <img src={users} alt="users" loading='lazy' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>


            {/* <Button className='ring-now'> Ring now </Button> */}
            <button onClick={() => scrollToDiv('go-to-calender')} className='book-appointment'> Book An Appointment </button>

            <div className='caret-container'>
                <button onClick={() => scrollToDiv('portfolio')} className='caret-down'>
                    <img src={Caretdown} alt='das' loading='lazy' />
                </button>

            </div>
        </div>
    )
}

export default HeroSection