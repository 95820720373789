import axios from 'axios';
import { CheckForHttpErrors } from 'src/utils/functions';

export const URL = 'https://backend.cyphion.com'
// export const URL = 'http://localhost:5000'


export default class HttpService {
      static POST(endpoint, body) {
            return new Promise((resolve, reject) => {
                  axios.post(`${URL}${endpoint}`, body)
                        .then(res => resolve(res.data))
                        .catch(err => reject(CheckForHttpErrors(err)))
            })
      }

      static async GET(endpoint) {
            return new Promise((resolve, reject) => {
                  axios.get(`${URL}${endpoint}`)
                        .then(res => resolve(res.data))
                        .catch(err => reject(CheckForHttpErrors(err)))
            })

      }
      static async DEL(endpoint) {
            return new Promise((resolve, reject) => {
                  axios.delete(`${URL}${endpoint}`)
                        .then(res => resolve(res.data))
                        .catch(err => reject(CheckForHttpErrors(err)))
            })

      }
      static async UPD(endpoint, body) {
            return new Promise((resolve, reject) => {
                  axios.put(`${URL}${endpoint}`, body)
                        .then(res => resolve(res.data))
                        .catch(err => reject(CheckForHttpErrors(err)))
            })

      }

}