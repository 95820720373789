import { useState } from 'react';
import toast from 'react-hot-toast';
import 'src/scss/layout/cwForm.scss';
import { generateAndSendPDF } from 'src/utils/functions';
import InputField from '../base/inputField';
import TextArea from '../base/textArea';

const ContentWritingForm = () => {
    const [formData, setFormData] = useState({
        companyName: '',
        contactEmail: '',
        contactPhoneNumber: '',
        originHistory: '',
        industry: '',
        primaryGoal: '',
        reason: '',
        description: '',
        competitors: '',
        keyFeatures: '',
        differentiation: '',
        keywords: '',
        missionStatement: '',
        taglines: '',
        useCookies: '',
        accountCreation: '',
        paymentMethods: '',
        saveCreditCardInfo: '',
        ageLimitation: '',
        refundRequirements: '',
        shippingPolicy: '',
        deliveryCharges: '',
        refundPolicyDays: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const newErrors = {};
        if (!formData.companyName) newErrors.companyName = 'Company Name is required.';
        if (!formData.contactEmail) newErrors.contactEmail = 'Contact Email is required.';
        if (!formData.contactPhoneNumber) newErrors.contactPhoneNumber = 'Contact Phone Number is required.';
        // Add validations for other fields

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            toast.error("Please fill the required fields");
            return;
        }

        // Clear previous errors if form is valid
        setErrors({});

        const isSubmitted = await generateAndSendPDF({ name: formData.companyName, email: formData.contactEmail, formData }, "Content Writing")

        if (isSubmitted) {
            setFormData({
                companyName: '',
                contactEmail: '',
                contactPhoneNumber: '',
                originHistory: '',
                industry: '',
                primaryGoal: '',
                reason: '',
                description: '',
                competitors: '',
                keyFeatures: '',
                differentiation: '',
                keywords: '',
                missionStatement: '',
                taglines: '',
                useCookies: '',
                accountCreation: '',
                paymentMethods: '',
                saveCreditCardInfo: '',
                ageLimitation: '',
                refundRequirements: '',
                shippingPolicy: '',
                deliveryCharges: '',
                refundPolicyDays: ''
            })

        }
        if (isSubmitted) {
            setFormData({
                companyName: '',
                contactEmail: '',
                contactPhoneNumber: '',
                originHistory: '',
                industry: '',
                primaryGoal: '',
                reason: '',
                description: '',
                competitors: '',
                keyFeatures: '',
                differentiation: '',
                keywords: '',
                missionStatement: '',
                taglines: '',
                useCookies: '',
                accountCreation: '',
                paymentMethods: '',
                saveCreditCardInfo: '',
                ageLimitation: '',
                refundRequirements: '',
                shippingPolicy: '',
                deliveryCharges: '',
                refundPolicyDays: ''
            })
        }
        setLoading(false)
    };
    return (
        <div className='cw-form'>
            <div className='cw-form-info'>
                <h1 className='heading'>Content Writing Questionnaire</h1>
                <p className='text'>What do you want your content to achieve? Let us help you get there</p>
            </div>
            <div className='cw-form-detail'>
                <div className='cw-div-1'>
                    <div className='child1'>
                        <InputField label={"Company Name"} placeholder={"Type Company Name"} required={true} name="companyName" value={formData.companyName} onChange={handleChange} />
                        {errors.companyName && <p className="error">{errors.companyName}</p>}

                    </div>
                    <div className='child2'>
                        <InputField label={"Contact Email"} placeholder={"Type Your Email"} required={true} name="contactEmail" value={formData.contactEmail} onChange={handleChange} />
                        {errors.contactEmail && <p className="error">{errors.contactEmail}</p>}
                    </div>
                </div>
                <div>
                    <InputField label={"Contact Phone Number"} placeholder={"Phone Number"} required={true} name="contactPhoneNumber" value={formData.contactPhoneNumber} onChange={handleChange} />
                    {errors.contactPhoneNumber && <p className="error">{errors.contactPhoneNumber}</p>}

                </div>
                <div>
                    <TextArea label={"What is the origin, history, timeline, chronology, achievements, and future plans of your company?"} name="originHistory" value={formData.originHistory} onChange={handleChange} />
                </div>
                <div>
                    <TextArea label={"What is the industry that your business caters to?"} name="industry" value={formData.industry} onChange={handleChange} />
                </div>
                <div>
                    <TextArea label={"What is the primary goal of your website? (e.g., showcase products, provide information, generate leads, sell services, etc.)"} name="primaryGoal" value={formData.primaryGoal} onChange={handleChange} />
                </div>
                <div>
                    <TextArea label={"What is the reason behind what you do; Heritage? Necessity? Passion"} name="reason" value={formData.reason} onChange={handleChange} />
                </div>
                <div>
                    <TextArea label={"Short description of your business in your own words?"} row={5} name="description" value={formData.description} onChange={handleChange} />
                </div>
                <div>
                    <TextArea label={"List three or more of your top competitors?"} row={3} name="competitors" value={formData.competitors} onChange={handleChange} />
                </div>
                <div>
                    <TextArea label={"Are there any key features or benefits you would like to highlight?"} name="keyFeatures" value={formData.keyFeatures} onChange={handleChange} />
                </div>
                <div>
                    <TextArea label={"What sets your company apart from your competitors"} name="differentiation" value={formData.differentiation} onChange={handleChange} />
                </div>
                <div>
                    <TextArea label={"Are there any specific keywords or phrases that are important for your industry? (These help with search engine optimization)"} name="keywords" value={formData.keywords} onChange={handleChange} />
                </div>
                <div>
                    <TextArea label={"What is your mission statement?"} name="missionStatement" value={formData.missionStatement} onChange={handleChange} />
                </div>
                <div>
                    <TextArea label={"Do you have any existing/preferred taglines and/or slogans that you would like us to use?"} name="taglines" value={formData.taglines} onChange={handleChange} />
                </div>

                <div className='cw-form-info'>
                    <h1 className='heading'>Policy Questions</h1>
                    <p className='text'></p>
                </div>
                <div>
                    <InputField label={"Do you use cookies?"} name="useCookies" value={formData.useCookies} onChange={handleChange} />
                </div>
                <div>
                    <InputField label={"Will there be an account creation option for your users?"} name="accountCreation" value={formData.accountCreation} onChange={handleChange} />
                </div>
                <div>
                    <InputField label={"What payment methods would you be using?"} name="paymentMethods" value={formData.paymentMethods} onChange={handleChange} />
                </div>
                <div>
                    <InputField label={"Would you save credit card information of your users?"} name="saveCreditCardInfo" value={formData.saveCreditCardInfo} onChange={handleChange} />
                </div>
                <div>
                    <InputField label={"Is there an age limitation on your products?"} name="ageLimitation" value={formData.ageLimitation} onChange={handleChange} />
                </div>
                <div>
                    <InputField label={"What are your refund requirements?"} name="refundRequirements" value={formData.refundRequirements} onChange={handleChange} />
                </div>
                <div>
                    <InputField label={"What is your shipping policy?"} name="shippingPolicy" value={formData.shippingPolicy} onChange={handleChange} />
                </div>
                <div>
                    <InputField label={"What are the delivery charges?"} name="deliveryCharges" value={formData.deliveryCharges} onChange={handleChange} />
                </div>
                <div>
                    <InputField label={"In how many days refund policy will be applicable?"} name="refundPolicyDays" value={formData.refundPolicyDays} onChange={handleChange} />
                </div>
                <div className="cw-buttons">
                    <button type='submit' className='submit-button' onClick={handleSubmit} disabled={loading}>Submit</button>
                    {/* <button type='button' className='cont-button'>Save And Continue Later</button> */}
                </div>
            </div>
        </div>
    )
}

export default ContentWritingForm