import { useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

// Custom hook to detect if an element with the specified ID is in the viewport
const useVisible = () => {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  const controls = useAnimation()

  useEffect(() => {
    if (isInView) {
      controls.start('visible')
    }
  }, [isInView])

  return { ref, controls }

};

export default useVisible;
