
// function CalendarComp({ month, year, handleDayClick, date, alreadyActive }) {
//     const daysInMonth = new Date(year, month + 1, 0).getDate(); // Get the number of days in the selected month
//     const firstDayOfMonth = new Date(year, month, 1).getDay(); // Get the day of the week of the first day of the month (0 - Sunday, 1 - Monday, ..., 6 - Saturday)
//     const today = new Date();
//     const currentMonth = today.getMonth();
//     const currentYear = today.getFullYear();
//     const currentDay = today.getDate();

//     const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
//     const monthStart = new Date(year, month, 1);
//     const monthEnd = new Date(year, month + 1, 0);

//     const weeks = [];
//     let currentWeek = [];

//     // Push empty placeholders for the days before the first day of the month
//     for (let i = 0; i < firstDayOfMonth; i++) {
//         currentWeek.push(<div key={`empty-${i}`} className="empty-cell"></div>);
//     }

//     // Push each day of the month
//     for (let day = 1; day <= daysInMonth; day++) {
//         const isActiveToday = year === currentYear && month === currentMonth && day === currentDay;

//         currentWeek.push(
//             <div key={day} className="calendar-day">
//                 <div className={`days ${isActiveToday ? 'today' : ''} ${date === day ? 'selected' : ''} ${alreadyActive === day ? "active" : ""}`}
//                     onClick={() => handleDayClick(day)}>
//                     {day}
//                 </div>
//             </div>
//         );
//         if ((firstDayOfMonth + day - 1) % 7 === 6 || day === daysInMonth) {
//             weeks.push(<div key={`week-${day}`} className="calendar-week">{currentWeek}</div>);
//             currentWeek = [];
//         }
//     }
//     return (
//         <div className="calendar-container">
//             <div className="weekdays">
//                 {weekdays.map(day => (
//                     <div key={day} className="weekday">{day}</div>
//                 ))}
//             </div>
//             {weeks}
//         </div>
//     );
// }
function CalendarComp({ month, year, handleDayClick, date, alreadyActive }) {
    const daysInMonth = new Date(year, month + 1, 0).getDate(); // Get the number of days in the selected month
    const firstDayOfMonth = new Date(year, month, 1).getDay(); // Get the day of the week of the first day of the month (0 - Sunday, 1 - Monday, ..., 6 - Saturday)
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time part to make comparison by date only
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const currentDay = today.getDate();

    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthStart = new Date(year, month, 1);
    const monthEnd = new Date(year, month + 1, 0);

    const weeks = [];
    let currentWeek = [];

    // Push empty placeholders for the days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
        currentWeek.push(<div key={`empty-${i}`} className="empty-cell"></div>);
    }

    // Push each day of the month
    for (let day = 1; day <= daysInMonth; day++) {
        const dayDate = new Date(year, month, day);
        const dayOfWeek = dayDate.getDay(); // Day of the week (0 - Sunday, 6 - Saturday)
        const isPast = dayDate < today;
        const isActiveToday = year === currentYear && month === currentMonth && day === currentDay;
        const dayClass = (dayOfWeek === 0 || dayOfWeek === 6 || isPast) ? "daysDisabled" : "days";

        currentWeek.push(
            <div key={day} className="calendar-day">
                <div className={`${dayClass} ${isActiveToday ? 'today' : ''} ${date === day ? 'selected' : ''} ${alreadyActive === day ? "active" : ""}`}
                    onClick={!isPast && dayOfWeek !== 0 && dayOfWeek !== 6 ? () => handleDayClick(day) : undefined}>
                    {day}
                </div>
            </div>
        );
        if ((firstDayOfMonth + day - 1) % 7 === 6 || day === daysInMonth) {
            weeks.push(<div key={`week-${day}`} className="calendar-week">{currentWeek}</div>);
            currentWeek = [];
        }
    }

    return (
        <div className="calendar-container">
            <div className="weekdays">
                {weekdays.map(day => (
                    <div key={day} className="weekday">{day}</div>
                ))}
            </div>
            {weeks}
        </div>
    );
}

export default CalendarComp;


