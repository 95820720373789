import "src/scss/base/modal.scss";
import blueEllipse from '../../Assests/images/Ellipse Full.png';
import ellipse from '../../Assests/images/Ellipse 1391.png';
import laptop from '../../Assests/images/laptop.png';
import Discover from '../../Assests/images/Rectangle 1040.png';
import Prototype from '../../Assests/images/Rectangle 1042.png';
import Test from '../../Assests/images/Rectangle 1044.png';
import Build from '../../Assests/images/Rectangle 1046.png';

export const Modal = ({ isOpen, onClose, data }) => {
    if (!isOpen) return null;
    return (
        <div className="modal-backdrop" onClick={onClose}>
            {/* <div className="modal-content" onClick={e => e.stopPropagation()}> */}
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="content-header">
                    <img src={blueEllipse} className="blue-ellipse" />
                    <img src={ellipse} className="black-ellipse" />
                    <div className="case-study-head">
                        <div className="intro">
                            <p className="case-text">Case Study</p>
                            <p className="project-text">{data?.title || "Project"}</p>
                        </div>
                        <div className="laptop">
                            <img src={laptop} className="laptop-img" />
                            <img src={data?.image} className="inside-laptop" />
                        </div>
                    </div>
                    <div className="case-study-main">
                        <div className="challenge">
                            <div className="challenge-text">Challenge</div>
                            <div className="challenge-box">
                                <div className="challenge-child">
                                    <p>
                                        {data?.challenge?.text1}
                                    </p>
                                    <p>
                                        {data?.challenge?.text2}
                                    </p>
                                </div>
                                <div className="challenge-child">
                                    <p>
                                        {data?.challenge?.text3}
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="solution">
                            <div className="solution-head">Solution</div>
                            <div className="solution-text">
                                <p>
                                    {data?.solution}
                                </p>
                            </div>

                        </div>

                        <div className="steps">
                            <div className="step12">
                                <div className="step">
                                    <div className="step-info">
                                        <p className="step-number">Step One:</p>
                                        <p className="step-heading">Discover</p>
                                        <p className="step-desc">
                                            {data?.step1}
                                        </p>
                                    </div>
                                    <div className="step-img-box">
                                        <img src={Discover} className="step-img" />
                                    </div>
                                </div>
                                <div className="step">
                                    <div className="step-info">
                                        <p className="step-number">Step Two:</p>
                                        <p className="step-heading">Prototype</p>
                                        <p className="step-desc">
                                            {data?.step2}
                                        </p>
                                    </div>
                                    <div className="step-img-box">
                                        <img src={Prototype} className="step-img" />
                                    </div>
                                </div>

                            </div>
                            {/* <div className="step3"> */}
                            <div className="step">
                                <div className="step-info">
                                    <p className="step-number">Step Three:</p>
                                    <p className="step-heading">Test</p>
                                    <p className="step-desc">
                                        {data?.step3}
                                    </p>
                                </div>
                                <div className="step-img-box">
                                    <img src={Test} className="step-img" />
                                </div>
                            </div>

                            {/* </div> */}
                            {/* <div className="step4"> */}
                            <div className="step">
                                <div className="step-info">
                                    <p className="step-number">Step Four:</p>
                                    <p className="step-heading">Build</p>
                                    <p className="step-desc">
                                        {data?.step4}
                                    </p>
                                </div>
                                <div className="step-img-box">
                                    <img src={Build} className="step-img" />
                                </div>
                            </div>

                            {/* </div> */}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};
