import { motion, useMotionTemplate, useMotionValue, useSpring } from "framer-motion";
import { useRef } from "react";

const TiltCard = ({ img, onClick }) => {
    return (
        <div style={{
            display: 'flex',
            width: "100%",
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'auto',
            padding: "10px 0"
        }}>
            <Card image={img} onClick={onClick} />
        </div>
    );
};

const ROTATION_RANGE = 30; // Reduced for a more subtle effect

const Card = ({ image, onClick }) => {
    const ref = useRef(null);

    const x = useMotionValue(0);
    const y = useMotionValue(0);

    const springConfig = { damping: 25, stiffness: 200 }; // Adjusted for smoother spring dynamics
    const xSpring = useSpring(x, springConfig);
    const ySpring = useSpring(y, springConfig);

    const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`;

    const handleMouseMove = (e) => {
        if (!ref.current) return;

        const rect = ref.current.getBoundingClientRect();
        const mouseX = ((e.clientX - rect.left) / rect.width - 0.5) * ROTATION_RANGE;
        const mouseY = ((e.clientY - rect.top) / rect.height - 0.5) * ROTATION_RANGE;

        x.set(mouseY * -1);
        y.set(mouseX);
    };

    const handleMouseLeave = () => {
        x.set(0);
        y.set(0);
    };

    return (
        <motion.div
            onClick={onClick}
            ref={ref}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={{
                perspective: '1000px',
                width: '100%',
                height: '450px',
                borderRadius: '20px',
                // background: 'linear-gradient(to bottom right, #b794f4, #9f7aea)',
                // boxShadow: boxShadow,
                transformStyle: 'preserve-3d',
                transform: transform
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transform: 'translateZ(50px)',
                }}
            >
                <img src={image} style={{
                    width: '90%', height: '90%', objectFit: 'cover', borderRadius: '20px',

                }} />
            </div>
        </motion.div>
    );
};

export default TiltCard;
