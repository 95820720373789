import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiMail } from "react-icons/fi";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { VscLoading } from "react-icons/vsc";
import { API } from "src/network/API";
import { removeError } from "src/utils/functions";


const initialData = {
  name: '',
  email: '',
  phNumber: '',
  pakage: ''
}
function FixedForm({ setOpen, data }) {

  const [formdata, setFormdata] = useState(initialData)
  const [error, setError] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    if (!formdata.name || !formdata.email || !formdata.phNumber) {

      setError(prev => ({
        ...prev,
        name: !formdata.name ? "Name is Required" : "",
        email: !formdata.email ? "email is Required" : "",
        phNumber: !formdata.phNumber ? "Phone no is Required" : "",
      }))
      setIsLoading(false)

      return
    } else {

      const sendData = {
        name: formdata.name,
        email: formdata.email,
        phoneNo: formdata.phNumber,
        message: formdata.pakage
      }

      await API.createForm(sendData).then(res => {
        setIsSubmit(true)
      }).catch(err => toast.error(err?.message)).finally(() => setIsLoading(false))

    }


  }

  const handleChange = (field, value) => {
    setFormdata(prev => ({
      ...prev, [field]: value
    }))

    setError(removeError(error, field))
  }


  useEffect(() => {
    setFormdata(prev => ({ ...prev, pakage: data }))
  }, [data])
  return (
    <div className='fixed-form'>
      {
        !isSubmit ? <form onSubmit={handleSubmit} className="form-chat d-flex flex-column  align-items-center  gap-16">
          <RxCross2 onClick={() => { setOpen(false); setFormdata(initialData) }} size={24} style={{ alignSelf: 'flex-end', marginRight: '10px', cursor: 'pointer' }} />
          <p>Contact Details</p>
          <div>
            <label>
              Full Name
              <input className={error?.name ? 'error' : ''} value={formdata.name} type="text" placeholder="Name" onChange={(e) => handleChange('name', e.target.value)} />
            </label>
          </div>
          <div>
            <label>
              Email Address
              <input className={error?.email ? 'error' : ''} type="email" value={formdata.email} placeholder="Email Address" onChange={(e) => handleChange('email', e.target.value)} />
            </label>
          </div>
          <div>
            <label>
              Contact
              <input className={error?.phNumber ? 'error' : ''} type="number" value={formdata.phNumber} placeholder="Contact No" onChange={(e) => handleChange('phNumber', e.target.value)} />
            </label>
          </div>
          <div>
            <label>
              Package Website
              <input type="text" value={formdata.pakage} disabled placeholder="Professional Website" onChange={(e) => handleChange('pakage', e.target.value)} />
            </label>
          </div>
          <button type="submit" className={`sub-btn-chat d-flex flex-row justify-content-center align-items-center gap-4 ${isLoading ? 'loading' : ''}`} >
            {isLoading ? <VscLoading className="spin" /> : <FiMail />}
            Submit
          </button>
        </form> : <div className="form-chat-after flex-column  align-items-center  gap-16 ">
          <RxCross2 onClick={() => { setOpen(false); setFormdata(initialData) }} size={24} style={{ float: 'right', marginRight: '10px', cursor: 'pointer' }} />
          <IoIosCheckmarkCircleOutline size={100} className="checkmark-icon" />
          <p className="thankyou">THANK YOU</p>
          <p className="infoText">We have successfully received your inquiry. One of our representative will get back to you shortly. In case if you have any further queries you can directly contact us at info@softlogicapp.com or call us at  (307) 417-0652.</p>
        </div>
      }


    </div>
  )
}

export default FixedForm