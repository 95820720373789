import Button from "./button"

function BottomSection() {
    return (
        <div className="main d-flex justify-content-center align-items-center flex-column ">
            <p className="para-bottom">Have more questions or ready to <span>start building </span> <br /> your dream product?</p>
            <Button className={'btn'} children={'Contact Us'} />
        </div>
    )
}

export default BottomSection