import 'font-awesome/css/font-awesome.min.css';
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import 'src/scss/index.scss';
import Loader from "./components/base/loader";
import FAQs from "./components/layout/FAQs";
import PrivicyPolicy from "./components/layout/PrivicyPolicy";
import { AllBriefForms } from "./components/layout/allBriefForms";
import ContentWritingForm from "./components/layout/content-form";
import Footer from './components/layout/footer';
import Header from './components/layout/header';
import Home from './components/layout/home';
import { LogoFrom } from "./components/layout/logo-form";
import SeoForm from "./components/layout/seo-form";
import SMMForm from "./components/layout/smmForm";
import TermsAndCondition from "./components/layout/termsandconditions";
import WebsiteForm from "./components/layout/webiste-from";

function App() {
  const [activeName, setActiveName] = useState("")
  const [isLoading, setIsloading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false)
    }, 3500);
  }, [])
  return (
    <>
      <Loader isLoading={isLoading} />
      <div style={{ display: isLoading ? 'none' : 'block' }}>
        <Toaster position={'top-center'} />
        <div style={{ position: 'relative' }} className="container-fluid">
          {/* <FixedButton /> */}

          <Router>
            <Header setActiveName={setActiveName} />
            <Routes>
              <Route path="/" element={<Home activeName={activeName} />} />
              <Route path="/faq" element={<FAQs />} />
              <Route path="/terms-condition" element={<TermsAndCondition />} />
              <Route path="/privacy-policy" element={<PrivicyPolicy />} />
              <Route path="/logo-form" element={<LogoFrom />} />
              <Route path="/seo-form" element={<SeoForm />} />
              <Route path="/content-writing-form" element={<ContentWritingForm />} />
              <Route path="/smm-form" element={<SMMForm />} />
              <Route path="/website-form" element={<WebsiteForm />} />
              <Route path="/admin-forms" element={<AllBriefForms />} />
            </Routes>
            {/* <LiveChat /> */}
            <Footer setActiveName={setActiveName} />
          </Router>
        </div>
      </div>
    </>
  );
}

export default App;
