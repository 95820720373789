import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { API } from "src/network/API";
import { formattedDate } from "src/utils/functions";
import Clogo from '../../Assests/images/Ellipse 441.svg';
import Calendely from "../base/Calendely";
import CalendarComp from "../base/calendarComp";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ContactForm from "../base/contactForm";
import { BiSolidVideoRecording } from "react-icons/bi";
import { RxCountdownTimer } from "react-icons/rx";

const initialData = {
  name: '',
  email: '',
  phoneNo: '',
  message: '',
}

function Calendar() {

  const [selecteddate, setSlecteddate] = useState('')
  const [selectedMonth, setSelectedMonth] = useState(0)
  const [selectYear, setSelectYear] = useState('')
  const [animateMonthChange, setAnimateMonthChange] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formdata, setFormdata] = useState(initialData)
  const [error, setError] = useState({})


  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const weekNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const currentDate = new Date(selectYear, selectedMonth, selecteddate);
  const dayOfWeek = currentDate.getDay();



  const handleDayClick = (e) => {
    setSlecteddate(e)
  }
  const nextMonth = () => {
    setAnimateMonthChange(true); //
    setSelectedMonth(prevIndex => (prevIndex + 1) % 12)
  }
  const prevMonth = () => {
    setAnimateMonthChange(true); //
    setSelectedMonth(prevIndex => (prevIndex === 0 ? 11 : prevIndex - 1))
  }

  const handleSubmit = async (e) => {

    const sendData = {
      ...e,
      scheduleDate: formattedDate(new Date(`${selecteddate ? selecteddate : new Date().getDate()}-${months[selectedMonth]}-${selectYear}`), 'dd-MMM-yyyy')
    }

    await API.createForm(sendData).then(res => {
      setOpenModal(true)
      setFormdata(initialData)
      setSlecteddate(new Date().getDate())
    }).catch(err => toast.error(err?.message)).finally(() => setIsLoading(false))

  }







  useEffect(() => {
    if (animateMonthChange) {
      const timeout = setTimeout(() => {
        setAnimateMonthChange(false); // Reset animation trigger after animation completes
      }, 1100); // Assuming animation duration is 1.1 seconds
      return () => clearTimeout(timeout);
    }
  }, [animateMonthChange]);


  useEffect(() => {
    let today = new Date()
    // setSlecteddate(today.getDate())
    setSelectedMonth(today.getMonth())
    setSelectYear(today.getFullYear())

  }, [])

  useEffect(() => {
    if (openModal) {
      // Disable body scrolling when the box is open
      document.body.style.overflow = 'hidden';
    } else {
      // Enable body scrolling when the box is closed
      document.body.style.overflow = 'auto';
    }

    return () => {
      // Cleanup: enable body scrolling on component unmount
      document.body.style.overflow = 'auto';
    };
  }, [openModal]);






  useEffect(() => {
    if (animateMonthChange) {
      const timeout = setTimeout(() => {
        setAnimateMonthChange(false); // Reset animation trigger after animation completes
      }, 1100); // Assuming animation duration is 1.1 seconds
      return () => clearTimeout(timeout);
    }
  }, [animateMonthChange]);


  useEffect(() => {
    let today = new Date()
    // setSlecteddate(today.getDate())
    setSelectedMonth(today.getMonth())
    setSelectYear(today.getFullYear())

  }, [])

  useEffect(() => {
    if (openModal) {
      // Disable body scrolling when the box is open
      document.body.style.overflow = 'hidden';
    } else {
      // Enable body scrolling when the box is closed
      document.body.style.overflow = 'auto';
    }

    return () => {
      // Cleanup: enable body scrolling on component unmount
      document.body.style.overflow = 'auto';
    };
  }, [openModal]);



  return (
    <div className="cal-div-main" id="contact">

      {openModal && <div className={`modal ${openModal ? "show-modal" : ""}`}>
        <div className="modal-content">
          <div className="close-button" onClick={() => setOpenModal(false)}>×</div>
          <IoIosCheckmarkCircleOutline size={80} className="checkmark-icon" />
          <h1><span className="chnge-color">Thank You</span></h1>
          <p className="mt-10">
            for contacting us!
          </p>
          <p className="mt-10">We've received your inquiry and will be in touch shortly.  If you'd like to speak with us right away, just give us a call.</p>
          <button className="mt-10 call-btn" >
            <a href="tel:(415) 907-4572" >
              Call Us
            </a>
          </button>
        </div>
      </div>}
      <img src={Clogo} className='Clogo' loading='lazy' />
      {/* <Calendely /> */}

      <div className="cal-inner-div d-flex ">
        <div className="first-chi d-flex flex-column align-items-start justify-content-evenly gap-16 " >
          <div className="d-flex flex-column  gap-16">
            <p className="cal-heading">Free Consultation + Quote</p>
            <div className="d-flex align-items-center justify-content-start gap-8">
              <RxCountdownTimer size={24} />
              <p className="fs-16 fw-bold">45 min</p>
            </div>
            <div className="d-flex align-items-center justify-content-start gap-8 ">
              <BiSolidVideoRecording size={24} />
              <p className="fs-16 fw-bold">Web conferencing details provided upon confirmation.</p>
            </div>
          </div>
          <div className="w-100">
            <ContactForm onSubmit={handleSubmit} setIsLoading={setIsLoading} isLoading={isLoading} setError={setError} setFormdata={setFormdata} formdata={formdata} error={error} />
          </div>
        </div>
        <div className="sec-chi">
          <div className="calendar">
            <div className="calen-first">
              <div className="year">{selectYear}</div>
              <div className="inner-opt">
                <div className="prev-month">
                  <FaChevronUp size={24} onClick={prevMonth} />
                </div>
                <div className={`month ${animateMonthChange ? 'animate' : ''}`} id="month-header">
                  {months[selectedMonth]}
                </div>
                <div className="next-month">
                  <FaChevronDown size={24} onClick={nextMonth} />
                </div>

              </div>
            </div>
            <div className="calen-second">
              <div className="circle"></div>
              <div className="calendar-data">{months[selectedMonth]} {selecteddate ? selecteddate : new Date().getDate()},{weekNames[dayOfWeek]}</div>
              <div className="calendar-comp">
                <CalendarComp month={selectedMonth} year={selectYear} handleDayClick={handleDayClick} date={selecteddate} alreadyActive={""} />
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>)
}
export default Calendar