import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';

const LaptopSliderComponent = ({ slide = false, imgId, currentImg, slidesData, initial }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [pause, setPause] = useState(false);


    useEffect(() => {
        if (slide && !pause) {
            const timer = setInterval(() => {
                setCurrentSlide(prevSlide => (prevSlide + 1) % slidesData.length);
                currentImg(prevSlide => (prevSlide + 1) % slidesData.length)

            }, 3000);
            return () => clearInterval(timer);
        }
    }, [slide, pause, currentSlide]);

    const initialRenderImage = initial

    useEffect(() => {
        if (imgId !== undefined && imgId !== null) {
            const index = slidesData.findIndex(slide => slide.id === imgId);
            if (index !== -1) {
                setCurrentSlide(index);
                currentImg(index)
                setPause(true);
                const timeout = setTimeout(() => {
                    setPause(false);
                }, 2000);
                return () => clearTimeout(timeout);
            }
        }
    }, [imgId]);

    return (
        <div className={"macbook"}>
            <div className="screen">
                <AnimatePresence >
                    {
                        slidesData.map((elem, index) => (
                            <div
                                style={{ opacity: index === currentSlide ? 1 : 0, transition: 'left 1s ease, opacity .5s ease', left: index === currentSlide ? 0 : '-50px' }}
                                className="viewport"
                            >
                                <a href={elem.image} target='_blank' prefetch="true">
                                    <img src={!slide ? initial : elem.image} alt={elem.text} style={{ objectFit: 'cover', height: '100%' }} />
                                </a>

                            </div>
                        ))
                    }
                </AnimatePresence>
            </div>
            <div className="base"></div>
            <div className="notch"></div>
        </div >
    );
};

export default LaptopSliderComponent;