import BottomSection from "../base/bottomSection";
import data from "../../data/faqs.json";
import { useEffect } from "react";

function FAQs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="faqs d-flex flex-column align-items-center" id="faqs">
        <div className="faqs-heading">
          <p className="faq-text">FAQ'S</p>
          <p className="faq-full">Frequently Asked Questions</p>
        </div>
        <p className="para fs-18">
          We've compiled a list of common questions our clients often ask about
          our services, processes, and what to expect when working with us. If
          you have any additional questions or need further clarification,
          please don't hesitate to contact us us via phone (307) 417-0652 or
          email info@Softlogicapp.com. Get in touch with one of the leading
          Mobile App Developers for exceptional services.
        </p>
        <div className="list">
          {data?.map((elem, index) => (
            <div key={index} className="list-div d-flex align-items-start">
              <div>
                <p className="list-heading">{elem.question}</p>
                <p className="list-desciption">{elem.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <BottomSection />
    </>
  );
}

export default FAQs;
