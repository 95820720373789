import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import mainLogo from "src/Assests/icons/Softlogic-Favicon.png";
import { API } from "src/network/API";
import { URL } from "src/network/httpService";
import 'src/scss/layout/allBriefForms.scss';
import Table from "../base/Table";
export const AllBriefForms = () => {
    const [isVerified, setIsVerified] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [briefForms, setBriefForms] = useState([])
    const [credential, setCredentials] = useState({
        email: "",
        password: ""
    })


    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials((prev) => ({ ...prev, [name]: value }));
    }


    const verifyAdmin = (e) => {
        e.preventDefault();
        if (credential.email === process.env.REACT_APP_ADMIN_EMAIL && credential.password === process.env.REACT_APP_ADMIN_PASSWORD) {
            toast.success("Admin Verified");
            const loggedIn = { isLogin: true };
            localStorage.setItem("isLogin", JSON.stringify(loggedIn));
            setIsVerified(true);
        } else {
            toast.error("Verification Failed");
        }
    }


    const getAllForms = async () => {
        setIsLoading(true)
        const res = await API.getAllBreifForms()
        const data = res?.data?.map((elem, index) => ({ number: index + 1, type: elem.type, file: elem?.formImage, download: elem?.formImage, userImage: elem?.userImage, delete: elem?.id }))
        setBriefForms(data)
        setIsLoading(false)
    }

    const deleteBriefForms = async (id) => {
        try {
            await API.deleteBreifForm(id);
            getAllForms();
            toast.success("Record deleted successfully")
        } catch (error) {
            toast.error('An error occurred while deleting the form. Please try again.');
        }
    };

    const downloadFile = async (filePath) => {
        const fullFilePath = URL + "/uploads/" + filePath;

        try {

            window.open(fullFilePath, '_blank');
        } catch (error) {
            toast.error('This record was deleted');
        }
    };





    const columns = [
        { header: 'No#', accessor: 'number' },
        { header: 'Type', accessor: 'type' },
        { header: 'File', accessor: 'file' },
        {
            header: 'Download',
            accessor: 'download',
            Cell: ({ value }) => (
                <button
                    className="download-button"
                    onClick={() => downloadFile(value)}
                >
                    Download
                </button>
            ),
        },
        {
            header: 'User Provided Image',
            accessor: 'userImage',
            Cell: ({ value }) => (
                <button
                    className={`download-button ${!value ? 'isDisabled' : ''}`}
                    onClick={() => downloadFile(value)}
                    disabled={!value}
                >
                    Download
                </button>
            ),
        },
        {
            header: 'Delete',
            accessor: 'delete',
            Cell: ({ value }) => (
                <button
                    className="delete-button"
                    onClick={() => deleteBriefForms(value)}
                >
                    <i class="fa fa-trash" aria-hidden="true" color="white"></i>
                </button>
            ),
        },
    ];


    useEffect(() => {
        const loggedIn = JSON.parse(localStorage.getItem('isLogin'));
        console.log(loggedIn);
        if (loggedIn && loggedIn.isLogin) {
            setIsVerified(true);
        }
    }, []);
    useEffect(() => { isVerified && getAllForms() }, [isVerified]);

    return (
        <div className="all-forms" onSubmit={verifyAdmin}>
            <Table columns={columns} data={briefForms} />
            <div className={`admin-modal ${isVerified ? 'verified' : ''}`}>
                <img src={mainLogo} className='admin-logo' />
                <h1 className="welcome-text">Welcome to Admin Portal</h1>
                <form className="admin-form">
                    <input type="email" name="email" placeholder="Enter email" className="admin-email" value={credential.email} onChange={handleChange} />
                    <input type="password" name="password" placeholder="Enter password" className="admin-password" value={credential.password} onChange={handleChange} />
                    <button type="submit" className="admin-verify">Verify</button>
                </form>
            </div>
            {isLoading && <div class="loader"></div>}
        </div>
    );
}