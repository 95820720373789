import HttpService from './httpService'

export class API {
  // #region Form
  static async createForm(formData) {
    return HttpService.POST('/form/create', formData)
  }

  static async getAllForm() {
    return HttpService.GET(`/form/all`)
  }

  static async deleteForm(id) {
    return HttpService.DEL(`/form/delete/${id}`)
  }

  static async updateForm(formData) {
    return HttpService.UPD(`/form/update`, formData)
  }

  // #region Newsletter


  static async createNewsletter(formData) {
    return HttpService.POST('/newsletter/create', formData)
  }

  static async getAllNewsletter() {
    return HttpService.GET(`/newsletter/all`)
  }

  static async deleteNewsletter(id) {
    return HttpService.DEL(`/newsletter/delete/${id}`)
  }

  static async updateNewsletter(formData) {
    return HttpService.UPD(`/newsletter/update`, formData)
  }

  static async getAllBreifForms() {
    return HttpService.GET(`/brief-form/all`)
  }

  static async breifForm(formData) {
    return HttpService.POST(`/brief-form/create`, formData)
  }

  static async deleteBreifForm(id) {
    return HttpService.DEL(`/brief-form/delete/${id}`)
  }

  static async uploadFile(file) {
    return HttpService.POST(`/uploads`, file)
  }

}
