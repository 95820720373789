import 'src/scss/base/inputField.scss'
const InputField = ({ label, type, placeholder, subtitle, required, onChange, value, name }) => {
    return (
        <div className='field-box'>
            <div className='label-box'>
                <p className='label'>{label}</p>
                {required ? <p className='required'>(Required)</p> : ''}
            </div>
            <p className='subtitle'>{subtitle}</p>
            <input title={label} type={type} name={name} placeholder={placeholder} onChange={onChange} value={value} className='field' required={required} />
        </div>
    )
}

export default InputField