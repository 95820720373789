import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';

const SliderComponent = ({ slide = false, imgId, currentImg, slidesData, initial }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [pause, setPause] = useState(false);
    useEffect(() => {
        if (slide && !pause) {
            const timer = setInterval(() => {
                setCurrentSlide(prevSlide => (prevSlide + 1) % slidesData.length);
                currentImg(prevSlide => (prevSlide + 1) % slidesData.length)
            }, 3000);
            return () => clearInterval(timer);
        }
    }, [slide, pause, currentSlide]);

    useEffect(() => {
        if (imgId !== undefined && imgId !== null) {
            const index = slidesData.findIndex(slide => slide.id === imgId);
            if (index !== -1) {
                setCurrentSlide(index);
                currentImg(index)
                setPause(true);
                const timeout = setTimeout(() => {
                    setPause(false);
                }, 2000);
                return () => clearTimeout(timeout);
            }
        }
    }, [imgId]);

    return (
        <div class="temp-wrapper">
            <div class="px">
                <div class="px__body">
                    <div class="px__body__cut"></div>
                    <div class="px__body__speaker"></div>
                    <div class="px__body__sensor"></div>

                    <div class="px__body__mute"></div>
                    <div class="px__body__up"></div>
                    <div class="px__body__down"></div>
                    <div class="px__body__right"></div>
                </div>

                <div class="px__screen">
                    <div class="px__screen__" >
                        <AnimatePresence >
                            {
                                slidesData?.length > 0 ? slidesData.map((elem, index) => (
                                    <div
                                        key={elem.id}
                                        className="px__screen__frame"
                                        style={{ opacity: index === currentSlide ? 1 : 0, transition: 'left 1s ease, opacity .5s ease', left: index === currentSlide ? 0 : '-50px' }}

                                    >
                                        <a href={!slide ? initial : elem.image} target='_blank' prefetch="true">
                                            <img src={!slide ? initial : elem.image} alt={elem.text} style={{ objectFit: 'cover', height: '100%' }} />
                                        </a>

                                    </div>
                                )) : <div className="px__screen__frame">
                                    <a href={initial} target='_blank' prefetch="true">
                                        <img src={initial} alt={"dassad"} style={{ objectFit: 'cover', height: '100%' }} />
                                    </a>
                                </div>
                            }
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SliderComponent;