import { useState } from "react";
import toast from "react-hot-toast";
import "src/scss/layout/smmForm.scss";
import { generateAndSendPDF } from "src/utils/functions";
import InputField from "../base/inputField";
import TextArea from "../base/textArea";

const SMMForm = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState({
        businessOverview: '',
        sellingPoints: '',
        targetAudience: '',
        demographics: '',
        socialMediaPlatforms: '',
        socialMediaPresence: '',
        marketingGoals: '',
        engagementGoals: '',
        competitors: '',
        admiredCompetitors: '',
        contentResonance: '',
        contentCreation: '',
        platformPreference: '',
        activePlatforms: '',
        measureSuccess: '',
        importantKPIs: '',
        audienceEngagement: '',
        responseStrategy: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const newErrors = {};
        if (!name) newErrors.name = 'Name is required.';
        if (!email) newErrors.email = 'Email is required.';
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors)
            toast.error("Please fill the required fields");
            return
        }

        const additionalData = {
            name,
            email,
            formData: formData
        };
        const type = 'Social Media ';

        const isSubmitted = await generateAndSendPDF(additionalData, type);

        if (isSubmitted) {
            toast.success('Form submitted successfully!');
            // Additional logic for successful submission
        }
        setLoading(false)
    };

    return (
        <div className='smm-form'>
            <div className='smm-form-info'>
                <h1 className='heading'>Social Media Questionnaire</h1>
                <p className='text'>Help us understand your audience and we'll create a social media strategy that connects</p>
            </div>
            <div className="smm-detail">
                <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                        <InputField label={"Name"}
                            name="name"
                            value={name}
                            required
                            onChange={e => setName(e.target.value)}
                        />
                        {errors.name && <p className="error">{errors.name}</p>}

                    </div>
                    <div style={{ width: "50%" }}>
                        <InputField label={"Email"}
                            name="email"
                            type={"email"}
                            value={email}
                            required
                            onChange={e => setEmail(e.target.value)}
                        />
                        {errors.email && <p className="error">{errors.email}</p>}
                    </div>
                </div>

                <div>
                    <TextArea label={"Can you provide an overview of your business and brand?"}
                        value={formData.businessOverview}
                        name={"businessOverview"}
                        onChange={handleChange}
                        row={4}
                        column={4}
                    />
                </div>
                <div >
                    <TextArea label={"What are your unique selling points and brand values?"}
                        value={formData.sellingPoints}
                        name={"sellingPoints"}
                        onChange={handleChange}

                        row={4}
                        column={4}

                    />
                </div>
                <div >
                    <TextArea label={"Who is your target audience on social media?"}
                        value={formData.targetAudience}
                        name={"targetAudience"}
                        onChange={handleChange}
                        row={4}
                        column={4}

                    />
                </div>
                <div >
                    <TextArea label={"Are there specific demographics or interests you want to reach?"}
                        value={formData.demographics}
                        name={"demographics"}
                        onChange={handleChange}

                        row={4}
                        column={4}

                    />
                </div>
                <div >
                    <TextArea label={"Which social media platforms are you currently active on?"}
                        value={formData.socialMediaPlatforms}
                        name={"socialMediaPlatforms"}
                        onChange={handleChange}
                        row={4}
                        column={4}

                    />
                </div>
                <div >
                    <TextArea label={"How would you describe the current state of your social media presence?"}
                        value={formData.socialMediaPresence}
                        name={"socialMediaPresence"}
                        onChange={handleChange}

                        row={4}
                        column={4}
                    />
                </div>
                <div >
                    <TextArea label={"What goals do you want to achieve through social media marketing?"}
                        value={formData.marketingGoals}
                        name={"marketingGoals"}
                        onChange={handleChange}

                        row={4}
                        column={4}
                    />
                </div>
                <div >
                    <TextArea label={"Are you looking to increase brand awareness, drive website traffic, generate leads, or enhance customer engagement?"}
                        value={formData.engagementGoals}
                        name={"engagementGoals"}
                        onChange={handleChange}
                        row={4}
                        column={4}
                    />
                </div>
                <div >
                    <TextArea label={"Who are your main competitors on social media?"}
                        value={formData.competitors}
                        name={"competitors"}
                        onChange={handleChange}
                        row={4}
                        column={4}
                    />
                </div>
                <div >
                    <TextArea label={"Are there specific competitors whose social media strategies you admire or want to emulate?"}
                        value={formData.admiredCompetitors}
                        name={"admiredCompetitors"}
                        onChange={handleChange}

                        row={4}
                        column={4}
                    />
                </div>
                <div >
                    <TextArea label={"What type of content resonates with your target audience?"}
                        value={formData.contentResonance}
                        name={"contentResonance"}
                        onChange={handleChange}
                        row={4}
                        column={4}
                    />
                </div>
                <div >
                    <TextArea label={"Do you have existing content that can be repurposed, or is there a need for new content creation?"}
                        value={formData.contentCreation}
                        name={"contentCreation"}
                        onChange={handleChange}
                        row={4}
                        column={4}
                    />
                </div>
                <div>
                    <TextArea label={"Do you have a preference for specific social media platforms (e.g., Facebook, Instagram, Twitter, LinkedIn)?"}
                        value={formData.platformPreference}
                        name={"platformPreference"}
                        onChange={handleChange}
                        row={4}
                        column={4}
                    />
                </div>
                <div>
                    <TextArea label={"Are there platforms where you think your target audience is most active?"}
                        value={formData.activePlatforms}
                        name={"activePlatforms"}
                        onChange={handleChange}
                        row={4}
                        column={4}
                    />
                </div>
                <div>
                    <TextArea label={"How do you currently measure success on social media?"}
                        value={formData.measureSuccess}
                        name={"measureSuccess"}
                        onChange={handleChange}
                        row={4}
                        column={4}
                    />
                </div>
                <div>
                    <TextArea label={"Are there specific metrics or key performance indicators (KPIs) that are important to you?"}
                        value={formData.importantKPIs}
                        name={"importantKPIs"}
                        onChange={handleChange}
                        row={4}
                        column={4}
                    />
                </div>
                <div>
                    <TextArea label={"How do you plan to engage with your audience on social media?"}
                        value={formData.audienceEngagement}
                        name={"audienceEngagement"}
                        onChange={handleChange}
                        row={4}
                        column={4}
                    />
                </div>
                <div>
                    <TextArea label={"What is your strategy for responding to comments, messages, and mentions?"}
                        value={formData.responseStrategy}
                        name={"responseStrategy"}
                        onChange={handleChange}
                        row={4}
                        column={4}
                    />
                </div>
                <div class="smm-buttons">
                    <button className='submit-button' onClick={handleSubmit} disabled={loading}>Submit</button>
                    {/* <button className='cont-button'>Save And Continue Later</button> */}
                </div>

            </div>

        </div>
    )

}

export default SMMForm