import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import toast from 'react-hot-toast';
import { API } from 'src/network/API';

export const removeError = (err, property) => {
    // console.log("-=", { ...err, [property]: "" })
    return { ...err, [property]: "" }
}


export const validateEmail = (email) => {
    // Regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

export const CheckForHttpErrors = error => {
    const { response } = error

    console.log(error)

    if (!response) {
        toast.error('No response from server')

        return 'Server not responding'
    }

    switch (response.status) {
        case 401:
            response.data.message
                ? toast.error(response.data.message)
                : toast.error('Your session is expired, please login again')
            break
        case 440:
            response.data.message
                ? toast.error(response.data.message)
                : toast.error('Your session is expired, please login again')
            break
        default:
            return {
                message: response.data.message || 'No response from server',
                status: response.status,
                data: response.data
            }
    }
}

export const formattedDate = (dateString, format) => {
    const date = new Date(dateString)

    if (isNaN(date.getTime()) || !dateString) {
        return 'N/A'
    }

    const day = date.getDate()
    const month = date.getMonth() + 1 // Months are zero-based
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: format?.includes('EEE') ? 'short' : undefined })
    const dayOfWeekInLong = date.toLocaleDateString('en-US', { weekday: format?.includes('EEEE') ? 'long' : undefined })
    const monthName = date.toLocaleDateString('en-US', { month: 'short' })
    const monthNameLong = date.toLocaleDateString('en-US', { month: 'long' })
    const period = hours >= 12 ? 'PM' : 'AM'

    const formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')} ${period}`
    const formattedTimewithoutampm = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

    const formattedTimeWithoutSecond = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')}`
    const formattedTimeWithoutSecondWithAMPM = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')}  ${period}`

    return format
        .replace('dd', day.toString().padStart(2, '0'))
        .replace('MMMM', monthNameLong)
        .replace('EEEE', dayOfWeekInLong)
        .replace('yyyy', year)
        .replace('EEE', dayOfWeek)
        .replace('MMM', monthName)
        .replace('MM', month.toString().padStart(2, '0'))
        .replace('hh:mm:x', formattedTimeWithoutSecond)
        .replace('hh:mm:z', formattedTimeWithoutSecondWithAMPM)
        .replace('hh:mm:ss:s', formattedTimewithoutampm)
        .replace('hh:mm:ss a', formattedTime)
}



export const generateAndSendPDF = async (additionalData, type) => {
    return new Promise((resolve, reject) => {
        const fullWidth = document.documentElement.scrollWidth;
        const fullHeight = document.documentElement.scrollHeight;

        html2canvas(document.documentElement, {
            scale: window.devicePixelRatio,
            useCORS: true,
            width: fullWidth,
            height: fullHeight,
            scrollX: 0,
            scrollY: 0,
            windowWidth: fullWidth,
            windowHeight: fullHeight,
            onclone: (documentClone) => {
                const header = documentClone.querySelector('.header');
                const footer = documentClone.querySelector('.footer');
                if (header) header.style.display = 'none';
                if (footer) footer.style.display = 'none';
            }
        }).then(async canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'pt', 'a4');
            const imgWidth = 595.28;
            const pageHeight = 841.89;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            const pdfBlob = pdf.output('blob');

            const formDataToSend = new FormData();
            formDataToSend.append('file', pdfBlob, 'generated-document.pdf');

            try {
                const uploadResponse = await API.uploadFile(formDataToSend);
                const data = additionalData?.userImage ? {
                    type,
                    username: additionalData?.name,
                    email: additionalData?.email,
                    formData: additionalData?.formData,
                    formImage: uploadResponse.filename,
                    userImage: additionalData?.userImage,
                } :
                    {
                        type,
                        username: additionalData?.name,
                        email: additionalData?.email,
                        formData: additionalData?.formData,
                        formImage: uploadResponse.filename
                    }
                const briefFormResponse = await API.breifForm(data);
                toast.success("Form Submitted");
                resolve(true); // Resolve the Promise with true upon successful submission
            } catch (error) {
                toast.error('Error:', error.message);
                reject(false); // Reject the Promise with false if an error occurs
            }
        }).catch(error => {
            toast.error('Error:', error.message);
            reject(false); // Reject the Promise with false if an error occurs during PDF generation
        });
    });
};



// const data = additionalData?.userImage ? {
//     type,
//     username: additionalData?.name,
//     email: additionalData?.email,
//     formData: additionalData?.formData,
//     image: uploadResponse.filename,
//     userImage: additionalData?.userImage,
// } :
//     {
//         type,
//         username: additionalData?.name,
//         email: additionalData?.email,
//         formData: additionalData?.formData,
//         image: uploadResponse.filename
//     }