import 'src/scss/base/textArea.scss'
const TextArea = ({ label, subtitle, name, type, required, row, column, value, onChange }) => {
    return (
        <div className='text-area-box'>
            <div className='text-area-label-box'>
                <p className='text-area-label'>{label}</p>
                {required ? <p className='text-area-required'>(Required)</p> : ''}
            </div>
            <p className='text-area-subtitle'>{subtitle}</p>
            <textarea
                className='text-area-field'
                rows={row}
                cols={column}
                name={name}
                value={value}
                onChange={onChange}
            />
        </div>
    )
}

export default TextArea

// rows={10} cols={50}