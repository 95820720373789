import { useEffect, useState } from 'react';
import BFYLogo from '../../Assests//images/Ellipse 426.svg';
import PtLogo from '../../Assests//images/Ellipse 430.svg';
import TigerLogo from '../../Assests//images/Ellipse 432.svg';
import SoukmaxLogo from '../../Assests//images/Ellipse 433.svg';
import FootPrintLogo from '../../Assests//images/Ellipse 435.svg';
import PopularIcon from '../../Assests//images/Ellipse 436.svg';
import myFree from '../../Assests//images/Ellipse 437.svg';
import AliRajputLogo from '../../Assests//images/Ellipse 439.svg';
import IconCheck from '../../Assests/icons/CheckIcon.svg';
import ellipse from '../../Assests/icons/Ellipse 12.svg';
import ring from '../../Assests/icons/Ellipse 425.svg';
import blueEllipse from '../../Assests/icons/Ellipse 6blue.svg';
import Tick from '../../Assests/icons/fi-ss-checkbox.svg';
import PricingData from '../../data/PricingData.json';


const billed = [
    { id: 1, label: "Web Development" },
    // { id: 2, label: "App Development" },
    // { id: 3, label: "E-Commerce" },
    // { id: 4, label: "SEO" },
    // { id: 5, label: "Domain & Hosting" },
    { id: 6, label: "Social Media" },
    // { id: 7, label: "Logo Designing" },
    { id: 8, label: "Hosting" },
];

const data = [
    { id: 1, title: "Client-First Style System" },
    { id: 2, title: "Style-Guide Page" },
    { id: 3, title: "Basic Interactions To Improve UX" },
    { id: 4, title: "Project Management With Notion" },
    { id: 5, title: "Fast Turnarounds" },
    { id: 6, title: "Communication Using Slack Or Email" },
    { id: 7, title: "Search Engine Optimization (SEO)" },
    { id: 8, title: "Page Performance Optimization" },
    { id: 9, title: "Accessibility Improvements" },
    { id: 10, title: "Responsive Website Development" },
    { id: 11, title: "Testing & Quality Assurance" },
    { id: 12, title: "Website Training & Materials" },
];

const PricingOne = ({ setOpen, setData, activeName }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [activeFilter, setActiveFilter] = useState("Web Development"); // Set default filter
    const [visibleItems, setVisibleItems] = useState([]);
    const [ringImages, setRingImages] = useState([
        { id: 1, localPath: BFYLogo },
        { id: 2, localPath: PopularIcon },
        { id: 3, localPath: FootPrintLogo },
        { id: 4, localPath: myFree },
        { id: 5, localPath: SoukmaxLogo },
        { id: 6, localPath: AliRajputLogo },
        { id: 7, localPath: TigerLogo },
        { id: 8, localPath: PtLogo },
    ]);


    const handleChange = (label) => {
        setActiveFilter(label);
    };


    useEffect(() => {
        // Filter pricing options based on the active category
        const data = PricingData.filter(item =>
            item.category.includes(activeFilter)
        );

        setVisibleItems(data);

    }, [activeFilter]); // Re-run the effect when activeFilter changes

    useEffect(() => {
        const interval = setInterval(() => {
            setRingImages(prevImages => {
                const nextIndex = (prevImages.findIndex(img => img.localPath === ringImages[0].localPath) + 1) % prevImages.length;
                return [...prevImages.slice(nextIndex), ...prevImages.slice(0, nextIndex)];
            });
        }, 3000); // Adjust the interval as needed

        return () => clearInterval(interval);
    }, [ringImages]);

    useEffect(() => {
        activeName ? setActiveFilter(activeName) : setActiveFilter("Web Development")
    }, [activeName])


    return (
        <div className='main-container pt-32' id='price'>

            <div className=' p-absolute w-100 d-flex justify-content-center my-45'>
                <p className='invesment-text p-0'>Investments</p>
                <p className='ring-now fw-medium   '> PRICING AND PLANS</p>

            </div>

            <div className="pricing-billing-duration" >
                <ul>
                    {billed.map((data) => (
                        <li className="nav-item" key={data.id}>
                            <button
                                onClick={() => handleChange(data.label)}
                                className={`nav-link ${data.label === activeFilter ? "active" : ""}`}
                            >
                                {data.label}
                            </button>
                        </li>
                    ))}
                </ul>
            </div >
            <div style={{ position: 'relative' }}>
                <div style={{ position: 'relative' }}>
                    <img className='ellipse' src={ellipse} />
                    <div className="row mb--50  " style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} >
                        {visibleItems.map((data, index) => (
                            <div className="col-lg-4 " key={index}>
                                <div className={`pricing-table ${(data?.popular || data?.title == "PRO (Yearly)") && "active"}`}>
                                    <div className="pricing-header">
                                        <div className='d-flex justify-content-between p-5'>
                                            <p className="title fs-fs-12">{data.title}</p>
                                            {data.popular && <p className='popular fs-12 p-5'>{data?.popular}</p>}
                                        </div>
                                        <div className="price-wrap d-flex justify-content-between align-items-baseline">

                                            <span className="amount "> {data.mprice}</span>
                                            <del className="">{data.yprice}</del>
                                        </div>
                                        <button onClick={() => {
                                            setOpen(true)
                                            setData(data.title)
                                        }} className="pricing-btn">
                                            <p className="axil-btn btn-large btn-borderd">Get Started</p>
                                        </button>
                                    </div>
                                    <div className="pricing-body" > {/* Set max height and overflow */}
                                        <ul>
                                            {data.facility.map((facility, idx) => (
                                                <li key={idx}><img src={IconCheck} alt='Check Icon' className='checkIcon' loading='lazy' /> {facility}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='box'>
                    <div className='card m-24 p-24'>
                        <p>All of our project includes</p>
                        <div className='d-flex align-items-center justify-content-center mt-16 gap-8'>
                            {data?.map((elem) => (
                                <span key={elem.id} className='d-flex align-items-center' ><img src={Tick} alt='tick' className='tickIcon' loading='lazy' /> {elem.title} </span>
                            ))}
                        </div>
                    </div>
                </div>
                <img className='blueEllipse' src={blueEllipse} />
            </div>
            <div className='bottom-div'>
                <div className='innerDiv'>
                    <p className='upperTitle '>Together We’ve</p>
                    <p className='achieved'>achieved</p>
                    {/* <img src={ZainergyLogo} alt='logo' className='center-logo' loading='lazy' />
                    <img src={JazzyLogo} alt='logo' className='center-logo' loading='lazy' />
                    <img src={ArabicLogo} alt='logo' className='center-logo' loading='lazy' /> */}
                </div>
                <div className='ring-div d-flex justify-content-end'>
                    <div >
                        <img src={ring} alt='ring' className='ring' loading='lazy' />

                        {ringImages?.map((elem, index) => (
                            <img src={elem.localPath} alt='ring' className={`brandLogos ${index === currentIndex ? 'active' : (index === (currentIndex + 1) % ringImages.length ? 'next' : 'previous')}`} loading='lazy' />
                        ))}

                        <img src={ringImages[currentIndex].localPath} alt='logo' className='brandLogos' loading='lazy' />

                    </div>
                </div>
                <div className='d-flex justify-content-center align-items-center' >
                    <p id='go-to-calender' className='para'>Book a <span>Free Consultation</span> and get a customized <span>Quote</span> from Our Expert Team</p>
                </div>
            </div>
        </div>
    );
}

export default PricingOne;