// import logoVideo from 'src/Assests/icons/logo.mp4';
import loaderJSON from '../../data/loader.json'

import Lottie from "lottie-react"

function Loader({ isLoading }) {

    return (
        <div className="loader-container" style={{ display: isLoading ? 'flex' : 'none' }} >
            {/* <video src={logoVideo} width="600" height="auto" autoPlay muted loop /> */}
            <Lottie
                animationData={loaderJSON}
                loop={true}
                autoplay={true}
                style={{ width: 300, height: 300 }}
            />
        </div>

    )
}

export default Loader