import { useEffect, useState } from "react";
import { BiSolidDollarCircle } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { FaLaptopCode } from "react-icons/fa";
import { FaListCheck } from "react-icons/fa6";
import { GiSandsOfTime } from "react-icons/gi";
import { HiViewGridAdd } from "react-icons/hi";
import { RiFileList3Fill } from "react-icons/ri";
import { SiVorondesign } from "react-icons/si";
import { TfiRulerPencil } from "react-icons/tfi";
import { TiGroup } from "react-icons/ti";
import { VscGraph } from "react-icons/vsc";
import firebase from '../../Assests/icons/1200px-Firebase_Logo.svg.png';
import airtable from '../../Assests/icons/Airtable.png';
import gitlab from '../../Assests/icons/GitLab_logo_2.svg.png';
import mocha from '../../Assests/icons/Mocha_logo-1.svg';
import sele from '../../Assests/icons/Selenium_logo-1.svg';
import zoom from '../../Assests/icons/Zoom-Logo-Vector-.png';
import apptim from '../../Assests/icons/apptim.svg';
import bStack from '../../Assests/icons/browserstack-ar21-1.svg';
import crash from '../../Assests/icons/crashlytics-45cf7071.png';
import figma from '../../Assests/icons/figma-5.svg';
import sheet from '../../Assests/icons/google-sheets-full-logo-1-1.svg';
import just from '../../Assests/icons/jest.svg';
import launch from '../../Assests/icons/launch-darkly-1.svg';
import sideImageone from "../../Assests/icons/leftSideDesign.svg";
import sideImageTwo from "../../Assests/icons/rightSideDesign.svg";
import Accordion from "../base/accordion";


const Timeline = () => {
    const [width, setWidth] = useState(window.innerWidth);


    const [activeIndex, setActiveIndex] = useState(null);
    const data = [
        {
            para: 'PHASE A - R&D'
        },
        {
            title: 'A1',
            logo: <BsSearch size={28} />,
            heading: "Specifications & Planning",
            dexcription: `<p>
            Through several guided discovery sessions, we build out a specifications document that clearly outlines our project goal.
            </p>
            <ul>
            <li>What are we building, for what users, and with what features?</li>
            <li>What languages, frameworks & services should we use to optimize cost vs functionality?</li>
            </ul>
            <p>Our team of professionals help guide this process through:</p>
            <ul>
            <li>Feature Suggestions (for example, did you think of building an admin panel to track usership & analytics).</li>
            <li>80/20 principles where its possible to build a similar feature but for much cheaper.</li>
            </ul>
            <p>Here is a sample of a Specifications Sheet.</p>`,
            icons: [
                <img src={airtable} loading='lazy' />,
                <img src={sheet} loading='lazy' />
            ]
        },
        {
            title: 'A2',
            logo: <SiVorondesign size={28} />,
            heading: "Designs, Wireframe & Prototype",
            dexcription: `<ul>
            <li>Based on our specifications, we create a beautiful wireframe + prototype.</li>
            <li>We then click through it together to make sure it looks and feels the way we want our final product to feel.</li>
            </ul>
            <p>Sample Wireframe & Prototype (feel free to click around on the prototype).</p>`,
            icons: [
                <img src={figma} loading='lazy' />
            ]
        },
        {
            title: 'A3',
            logo: <GiSandsOfTime size={28} />,
            heading: "Estimates & Timeline",
            dexcription: `<ul>
            <li>Based on specifications and designs, we’ll create a detailed estimate on the project cost + a timeline to deployment.</li>
            <li>The estimate can then be modified, removing or adding optional features to suit your budget</li>
            </ul>`,
            icons: [
                <img src={airtable} loading='lazy' />,
                <img src={sheet} loading='lazy' />
            ]
        },
        {
            para: 'PHASE B- Development'
        },
        {
            title: 'B1',
            logo: <RiFileList3Fill size={28} />,
            heading: "Build",
            dexcription: `<ul>
            <li>Now we start bringing your project to life with code.</li>
            <li>Lean Methodology.</li>
            <li>Emphasis on releasing features as soon as possible so that: <br/>  1.) You can test it and <br/> 2.) Your clients can use it.</li>
            </ul>`,
            icons: [
                <img src={airtable} loading='lazy' />,
                <img src={gitlab} loading='lazy' />
            ]
        },
        {
            title: 'B2',
            logo: <FaListCheck size={28} />,
            heading: "Test",
            dexcription: `<ul>
            <li>Important & underrated. Quality of final product comes down to solid QA.</li>
            <li>Manual Testing - a real human, using your product on a real device, and tearing it apart to find any bug that needs fixing. I Automation Testing - automatic testing that mimics the human. I Unit Testing - automatic testing done directly on the code, not the product</li>
            <li>Supported devices: All modern iOS device, Android devices and computer browsers</li>
            </ul>`,
            icons: [
                <img src={bStack} loading='lazy' />,
                <img src={just} loading='lazy' />,
                <img src={mocha} loading='lazy' />,
                <img src={apptim} loading='lazy' />,
                <img src={sele} loading='lazy' />,
            ]
        },
        {
            title: 'B3',
            logo: <FaLaptopCode size={28} />,
            heading: "Deploy",
            dexcription: `<ul>
            <li>Release code to Gitlab (Where we store code, which is always accessible to you).</li>
            <li>DevOps to automate these things (trigger test automatically, use Firebase to automatically report bugs and crashes).</li>
            </ul>`,
            icons: [
                <img src={firebase} loading='lazy' />,
                <img src={gitlab} loading='lazy' />,
                <img src={crash} loading='lazy' />,
            ]
        },
        {
            title: 'B4',
            logo: <TfiRulerPencil size={28} />,
            heading: "Measure",
            dexcription: `<ul>
            <li>Letting the client play around with the app and receiving feedback.</li>
            <li>Putting out a Beta (if the app is already released) to let a small subsection of the user base play around with the updated app, and receive feedback before rolling it out to the entire userbase.</li>
            </ul>`,
            icons: [
                <img src={launch} loading='lazy' />,
                <img src={zoom} loading='lazy' />,
            ]
        },
        {
            title: 'B5',
            logo: <VscGraph size={28} />,
            heading: "Maintain",
            dexcription: `card`
        },
    ]

    const handleItemClick = (index) => {
        if (width > 767) {
            setActiveIndex(index);
        } else {
            setActiveIndex(activeIndex === index ? null : index);
        }

    };

    const updateWidth = () => {
        setWidth(window.innerWidth);
        if (window.innerWidth > 767) {
            setActiveIndex(1);
        }
    };


    useEffect(() => {
        // Add event listener when component mounts
        window.addEventListener('resize', updateWidth);
        setActiveIndex(1)
        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);


    return (
        <section className="timeline py-10 f-family-poppin" id="ourProcess">
            <div className="container-fluid bg-sec">
                <div className="title-Text">
                    <p className="mainText">
                        Our <span className="changeColor">mobile app</span> & <br />
                        <span className="changeColor">Web development </span> process
                    </p>
                    <p className="secondary-text">
                        Process is everything. It’s our IP, our bread and butter. Years and
                        years of experience, mistakes and success have <br /> gone into
                        creating the best possible experience, and equip us with the best
                        tools to navigate the sometimes <br /> challenging waters of app
                        development.
                    </p>
                </div>
                <div className="image-div">
                    <div className="lft-sec">

                        {data?.map((elem, index) => (
                            <>
                                {elem?.para ? <p className="sub-head">{elem?.para}</p>
                                    :
                                    <div className={`d-flex ticket ${activeIndex === index ? 'active' : ''}`} onClick={() => handleItemClick(index)}>
                                        <div className="r-side d-flex justify-content-center align-items-center">
                                            {elem?.title}
                                            <div className="sm-rnd">{' '}</div>
                                        </div>
                                        <div className={`d-flex l-side justify-content-center align-items-center ${activeIndex === index ? 'active' : ''}`}>
                                            <div className="box-rounded d-flex justify-content-start  align-items-center gap-16">
                                                <div className=" logo-round">
                                                    {elem?.logo}
                                                </div>
                                                <p className="d-flex text-left align-items-center">{elem?.heading}</p>
                                            </div>
                                        </div>
                                        <Accordion
                                            key={index}
                                            isActive={activeIndex === index}
                                            index={index}
                                            {...elem}
                                        />
                                    </div>}
                            </>
                        ))}
                    </div>
                    <div className="rig-sec d-flex align-items-center justify-content-center" style={{
                        paddingBottom: '0px'
                    }}>
                        <div className="process-box">
                            {data[activeIndex]?.dexcription !== 'card' ? <>
                                <p className="ph-heading">PHASE {data[activeIndex]?.title}</p>

                                <p className="ph-heading2">
                                    {activeIndex === 1 ? ' Comprehensive Approach to Website and App Development Process' : data[activeIndex]?.heading}
                                </p>
                                <div className="desc">
                                    {data[activeIndex]?.dexcription && (
                                        <div dangerouslySetInnerHTML={{ __html: data[activeIndex]?.dexcription }} />
                                    )}
                                </div>
                                <div>
                                    <p>
                                        Tools Used:
                                    </p>
                                    <div className="tags d-flex align-items-center gap-8">
                                        {data[activeIndex]?.icons?.map((item) => (
                                            <div>
                                                {item}
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </> : <div>
                                <div className="card-process-div ">
                                    <div className="process-card">
                                        <div className="d-flex align-items-center justify-content-start gap-16 bordered-process">
                                            <BiSolidDollarCircle size={50} />
                                            <p>A-L-Cart</p>
                                        </div>
                                        <p>
                                            Pay by the hour for any work we do.
                                        </p>
                                    </div>
                                    <div className="process-card">
                                        <div className="d-flex align-items-center justify-content-start gap-16 bordered-process">
                                            <TiGroup size={50} />
                                            <p>Retainer</p>
                                        </div>
                                        <p>
                                            Retain some amount of time from our engineers per month to fix bugs, maintain code and even implement new features
                                        </p>
                                    </div>
                                    <div className="process-card">
                                        <div className="d-flex align-items-center justify-content-start gap-16 bordered-process">
                                            <HiViewGridAdd size={50} />
                                            <p>Custom</p>
                                        </div>
                                        <p>
                                            Have something else in mind? Let us know, we’re here to support your growth.
                                        </p>
                                    </div>
                                </div>
                                <p className="para-procss">
                                    <b>Keep in mind:</b> The goal is for your product to succeed, and attract both clients and revenue. Those clients will want better features, and we’ll be here to build that V2, V3 etc when the time comes. We love long term partnerships.
                                </p>
                            </div>}
                        </div>
                    </div>
                    <div className="center-div">

                        <div className="left-side">
                            <img src={sideImageone} alt="ad" loading='lazy' />

                        </div>
                        <div className="right-side">
                            <img src={sideImageTwo} alt="ad" loading='lazy' />
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default Timeline;
