import { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { API } from 'src/network/API'
import american from '../../Assests/icons/american-express-svgrepo-com.svg'
import discover from '../../Assests/icons/discover-svgrepo-com.svg'
import masterCard from '../../Assests/icons/mastercard-svgrepo-com.svg'
import visa from '../../Assests/icons/visa-logo-svgrepo-com.svg'
import wire from '../../Assests/icons/wire-transfer-1.svg'
import logo from '../../Assests/images/logo-cyphion.png'


const Footer = ({ setActiveName }) => {

    const [email, setEmail] = useState('')
    const [open, setOpen] = useState(false);
    const [showNavbar, setShowNavbar] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [activeClass, setActiveClass] = useState('')
    const [isBodyOverflowHidden, setIsBodyOverflowHidden] = useState(false)
    const dropdownRef = useRef(null);

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    };
    const closeDropdown = () => {
        setOpen(false);
    };
    const scrollToDiv = (divId) => {
        setActiveClass(divId)
        closeDropdown()
        var element = document.getElementById(divId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const onRouteChange = () => {
        window.scrollTo(0, 0);
    };

    const handleSubmit = async () => {

        if (!email) {
            toast.error('Please Write Email to subscribe')
        }
        else {
            await API.createNewsletter({ email }).then(res => {
                toast.success(res?.message)
                setEmail('')
            }).catch(err => toast.error(err?.message))
        }

    }

    return (
        <>

            <div className="footer w-100" >
                <div className='inner-div'>

                    <div className='topDiv d-flex justify-content-between '>
                        <div >
                            <img src={logo} loading='lazy' />
                            <p className='py-10  fs-20'>
                                Single to manage all your <br />     businesses
                            </p>
                            <p className='py-16  fs-14'>
                                Production-ready & easy to use Admin Template <br /> for Reliability and Customizability.
                            </p>
                            <p className='py-16'>
                                get our weekly newsletter
                            </p>
                            <div className='input-container'>
                                <button onClick={handleSubmit}>Send</button>
                                <input type="email" required name="" id="" placeholder='type in your email' value={email} onChange={e => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div>
                            <p className='fs-24 py-24'>Services</p>
                            <p className='fs-18 l-h  py-16'>
                                {/* Video Animation<br /> */}
                                {/* Content Writing<br /> */}
                                <Link to={`/?active=Web Development`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("Web Development"); }}>
                                    Web App Development
                                </Link>
                                {/* Web App Development<br /> */}
                                <Link to={`/?active=Web Development`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("App Development"); }}>
                                    Mobile App
                                </Link>
                                <Link to={`/?active=E-Commerce`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("E-Commerce"); }}>
                                    E-Commerce
                                </Link>
                                {/* Wordpress Development<br /> */}
                                {/* Shopify Development<br /> */}
                                <Link to={`/?active=Logo Designing`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("Logo Designing"); }}>
                                    Logo Design
                                </Link>
                                <Link to={`/?active=Web Development`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("Web Development"); }}>
                                    Web Design
                                </Link>

                                {/* Logo Design<br /> */}
                                Branding<br />


                                {/* Mobile Application<br /> */}
                                <Link to={`/?active=Domain & Hosting`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("Domain & Hosting"); }}>
                                    Domain & Hosting
                                </Link>
                                {/* Domain & Hosting<br /> */}
                                <Link to={`/?active=SEO`} className="secondary-button d-flex align-items-center flex-row justify-content-between btn-Link" onClick={() => { scrollToDiv("price"); setShowNavbar(false); setActiveName("SEO"); }}>
                                    SEO
                                </Link>
                                {/* SEO<br /> */}
                            </p>
                        </div>
                        <div>
                            <div>
                                <p className='fs-24 py-24'>
                                    Get Started
                                </p>
                                <p className='fs-18 py-16 l-h'>
                                    Start a Project<br />
                                    Schedule a free call
                                </p>
                            </div>
                            <div>
                                <p className='fs-24 py-24'>Contact Us</p>
                                {/* <p className='fs-18 py-16'>info@softlogicapp.com</p> */}
                                <p className='fs-18 py-16'>+923402804201</p>
                                {/* <p className='fs-18 py-16'>312 W. 2nd St #2085 Casper,<br /> WY 82601 United States</p> */}
                                <p className='fs-18 py-16'>Mezzenine no.103, Taj Mahal centre,<br /> Block no.1, KDAscheme no.24,<br /> Gulshan-e-Iqbal, Karachi, Pakistan.</p>
                                <div className='logos-div'>
                                    <span className='logo-span'>
                                        <img src={visa} alt='social Logo' className='socialIcons' loading='lazy' />
                                    </span>
                                    <span className='logo-span'>
                                        <img src={masterCard} alt='social Logo' className='socialIcons' loading='lazy' />
                                    </span>
                                    <span className='logo-span'>
                                        <img src={discover} alt='social Logo' className='socialIcons' loading='lazy' />
                                    </span>
                                    <span className='logo-span'>
                                        <img src={american} alt='social Logo' className='socialIcons' loading='lazy' /> <br />
                                    </span>
                                    <span className='logo-span'>
                                        <img src={wire} alt='social Logo' className='socialIcons' loading='lazy' />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bottom-div d-flex justify-content-between align-items-center '>
                        <span>
                            {/* <p>© 2024 octanexdigitalllc.com. All rights reserved.</p> */}
                            <p className='text-center'>Copyright © Cyphion (Private) Limited All rights reserved {(new Date()).getFullYear()}</p>
                        </span>

                        {/* <span className='d-flex gap-8 tern' onClick={onRouteChange}>
                            <Link to="/terms-condition">Terms & Conditions</Link>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </span> */}
                        {/* <img src={logo} className='abs-logo' loading='lazy' /> */}
                    </div>
                </div>
            </div>
            {/* <div style={{ height: '40vh' }}>

            </div> */}
        </>
    )
}

export default Footer