import "src/scss/base/modalSmall.scss";

export const ModalSmall = ({ isOpen, onClose, data }) => {
    if (!isOpen) return null;
    return (
        <div className="modal-backdrop" onClick={onClose}>
            <div className="content" onClick={e => e.stopPropagation()}>
                <img src={data?.modalImage} alt={data?.title} className="modal-img" />
            </div>
        </div>
    );
};
