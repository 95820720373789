import { useEffect, useRef, useState } from 'react';
import { BiSolidDollarCircle } from "react-icons/bi";
import { FaChevronDown } from "react-icons/fa6";
import { HiViewGridAdd } from "react-icons/hi";
import { TiGroup } from "react-icons/ti";


function Accordion({ heading, dexcription, isActive, logo, icons }) {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(0);
    const contentRef = useRef(null);

    const innerStyle = {
        height: isActive ? `${height}px` : '0px',
    };

    const updateWidth = () => {
        setWidth(window.innerWidth);
    };


    useEffect(() => {
        // Add event listener when component mounts
        window.addEventListener('resize', updateWidth);

        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    useEffect(() => {
        if (isActive && contentRef.current) {
            const contentHeight = contentRef.current.scrollHeight;
            setHeight(contentHeight);
        } else {
            setHeight(0);
        }
    }, [isActive, dexcription, width]);



    return (
        <div className='panel' role='tabpanel' aria-expanded={isActive}>
            <div className='panel__label' role='tab' >
                <div className='d-flex align-items-center gap-8'>
                    {logo}
                    {heading}
                </div>
                <span className='panel__label__icon-arrow'>
                    <FaChevronDown />
                </span>
            </div>
            <div className='panel__inner' style={innerStyle} aria-hidden={!isActive}>
                <div className='panel__content' ref={contentRef}>
                    <div className="process-box">
                        {dexcription !== 'card' ? <>
                            <div className="desc">
                                {dexcription && (
                                    <div dangerouslySetInnerHTML={{ __html: dexcription }} />
                                )}
                            </div>
                            <div>
                                <p>
                                    Tools Used:
                                </p>
                                <div className="tags d-flex align-items-center gap-8">
                                    {icons?.map((item) => (
                                        <div>
                                            {item}
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </> : <div>
                            <div className="card-process-div">
                                <div className="process-card">
                                    <div className="d-flex align-items-center justify-content-start gap-16 bordered-process">
                                        <BiSolidDollarCircle size={50} />
                                        <p>A-L-Cart</p>
                                    </div>
                                    <p>
                                        Pay by the hour for any work we do.
                                    </p>
                                </div>
                                <div className="process-card">
                                    <div className="d-flex align-items-center justify-content-start gap-16 bordered-process">
                                        <TiGroup size={50} />
                                        <p>Retainer</p>
                                    </div>
                                    <p>
                                        Retain some amount of time from our engineers per month to fix bugs, maintain code and even implement new features
                                    </p>
                                </div>
                                <div className="process-card">
                                    <div className="d-flex align-items-center justify-content-start gap-16 bordered-process">
                                        <HiViewGridAdd size={50} />
                                        <p>Custom</p>
                                    </div>
                                    <p>
                                        Have something else in mind? Let us know, we’re here to support your growth.
                                    </p>
                                </div>
                            </div>
                            <p className="para-procss">
                                <b>Keep in mind:</b> The goal is for your product to succeed, and attract both clients and revenue. Those clients will want better features, and we’ll be here to build that V2, V3 etc when the time comes. We love long term partnerships.
                            </p>
                        </div>}
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Accordion;